import React from 'react'
import { images } from 'config/images'

export const CustomSoftDevSerProps = {
  ppcbannerProps: {
    heading: (
      <>
        <span className="highlight-head">Custom software development</span>{' '}
        services to give your business an edge
      </>
    ),
    para: (
      <>
        Creating custom-built modern{' '}
        <span className="color-through">software of tomorrow</span>, today. Our
        team of developers and creators help you build custom software that
        makes your business workflows faster and more efficient
      </>
    ),
    formCTA: 'Talk to our experts',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        <span className="highlight-head">Tailored-to-needs</span> applications
        of Fortune 1000 companies
      </>
    ),
    buildAppPara: (
      <>
        From cutting-edge POS systems to field applications that prioritize
        service needs, to IoT{' '}
        <span className="color-through">products that connect people</span> with
        devices and augment their experiences. The breadth of expertise and
        experience of our teams will help you stand out.
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Our <span className="highlight-head">Custom Software Development</span>{' '}
        Services
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Technology Consulting',
        serviceParagraph: (
          <>
            This is a partnership. <strong>Your goals are ours</strong>, your
            needs drive our direction. We listen to your objectives, challenges,
            and hopes so we can align on processes, technologies, or teams to
            invest in.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'UX',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: <>Custom Web and Mobile Apps Development </>,
        serviceParagraph: (
          <>
            Close the gap between imagined possibility and reality by{' '}
            <strong>building high performant, scalable</strong> web and mobile
            apps using modern languages and frameworks.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Web',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'API Development and Integration',
        serviceParagraph: (
          <>
            <strong>Connect existing systems</strong> and applications by
            building APIs for mobile, cloud, web sockets, browsers, and
            databases to facilitate access to application data, features, and
            business logic.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'API',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: <>Re-engineering and Performance Tuning</>,
        serviceParagraph: (
          <>
            Growing businesses face scalability and performance issues. We
            become your extended team to{' '}
            <strong>improve the architecture,</strong> refactor old code,
            improve deployment and API integrations.
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Re-engineering',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: 'Quality Assurance and Software Testing',
        serviceParagraph: (
          <>
            We don’t just test functionality or match developed features with
            your requirements, we <strong>test to ensure</strong> that users
            will be able to use the application as intended.
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'Quality',
        serviceClass: 'ice',
      },
      {
        id: '6',
        serviceHeading: 'Continuous Integration and Deployment',
        serviceParagraph: (
          <>
            Our DevOps team will work with your team to establish detailed{' '}
            <strong>deployment objectives and timelines</strong>, covering
            configuration, testing, governance, troubleshooting and more.
          </>
        ),
        serviceIcon: images.productDevelopment,
        serviceAlt: 'Integration',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Understanding your business goals',
        workingBenefitsContent: (
          <>
            Let’s discuss your short and <strong>long-term vision</strong>{' '}
            in-depth. When we know your goals and understand existing software,
            we are able to ensure the success of the project.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Well-architectured solution for scale',
        workingBenefitsContent: (
          <>
            We take a <strong>pragmatic approach</strong> to software
            architecture and design. Choosing important areas worth investing
            upfront, and prioritize others afterwards. We also provide data
            protection consultation.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Goal driven User Experience (UX) design',
        workingBenefitsContent: (
          <>
            Good software design helps <strong>drive user satisfaction.</strong>{' '}
            We perform UX research to align overall experience with your goals
            and users’ needs and let you make well-informed decisions.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Recent <span className="highlight-head">Case studies </span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        Customer <span className="highlight-head">Success Stories</span>
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  // workingTeamProps: {
  //   heading: (
  //     <>
  //       Talented {''}
  //       <span className="highlight-head">Team of Experts</span>
  //     </>
  //   ),
  //   workingTeamlistProps: [
  //     {
  //       workingPersonClass: 'light-blue-grey-three',
  //       workingPersonName: 'Maitrik Kataria',
  //       workingPersonDesignation: 'Sr UI UX Designer',
  //       workingPersonPara:
  //         'A UX story teller with skills in UX strategy, User research, and prototyping for mobile apps.',
  //       workingPersonTag: [
  //         'UX',
  //         'UI',
  //         'User Resarch',
  //         'UX Strategy',
  //         'Usability Testing',
  //       ],
  //       workingPersonImg: images.maitrikpic,
  //       workingPersonImg2x: images.maitrikpic2x,
  //       workingPersonAlt: 'Maitrik Kataria',
  //     },
  //     {
  //       workingPersonClass: 'cloudy-blue',
  //       workingPersonName: 'Hiren Dhaduk',
  //       workingPersonDesignation: 'Software Architect and Tech Lead',
  //       workingPersonPara:
  //         'System architect with love for building scalable software applications with complex DBs.',
  //       workingPersonTag: [
  //         'Application Architecture',
  //         'Cloud Architecture',
  //         'DB Design',
  //       ],
  //       workingPersonImg: images.hirenpic,
  //       workingPersonImg2x: images.hirenpic2x,
  //       workingPersonAlt: 'Hiren Dhaduk',
  //     },
  //     {
  //       workingPersonClass: 'faded-pink',
  //       workingPersonName: 'Ravi Chothani',
  //       workingPersonDesignation: 'UI UX Designer',
  //       workingPersonPara:
  //         'A seasoned UI UX designer with flawless skills for creating one-of-a-kind identity. ',
  //       workingPersonTag: ['UI', 'UX', 'Wireframing', 'Prototyping'],
  //       workingPersonImg: images.ravipic,
  //       workingPersonImg2x: images.ravipic2x,
  //       workingPersonAlt: 'Ravi Chothani',
  //     },
  //     {
  //       workingPersonClass: 'soft-pink',
  //       workingPersonName: 'Paresh Mayani',
  //       workingPersonDesignation: 'Project Manager',
  //       workingPersonPara:
  //         'Speaker, mentor & open source enthusiast with skills in building mobile solutions that users love.',
  //       workingPersonTag: ['Agile', 'Project Management', 'Tech Lead'],
  //       workingPersonImg: images.pareshpic,
  //       workingPersonImg2x: images.pareshpic2x,
  //       workingPersonAlt: 'Paresh Mayani',
  //     },
  //     {
  //       workingPersonClass: 'beige-three',
  //       workingPersonName: 'Tejas Ardeshna',
  //       workingPersonDesignation: 'Sr. App Developer',
  //       workingPersonPara:
  //         'Experienced iOS developer with high adaptability and in-depth knowledge of SDKs and libraries. ',
  //       workingPersonTag: [
  //         'iOS',
  //         'Android',
  //         'Swift',
  //         'Flutter',
  //         'React Native',
  //       ],
  //       workingPersonImg: images.tejaspic,
  //       workingPersonImg2x: images.tejaspic2x,
  //       workingPersonAlt: 'Tejas Ardeshna',
  //     },
  //     {
  //       workingPersonClass: 'light-blue-grey-two',
  //       workingPersonName: 'Ronak Kotecha',
  //       workingPersonDesignation: 'Sr. Backend Engineer',
  //       workingPersonPara:
  //         'Enterprise software architect and a strict follower of agile and scrum methodologies.',
  //       workingPersonTag: ['PHP', 'NodeJS', 'ExpressJS', 'AWS', 'DevOps'],
  //       workingPersonImg: images.ronakpic,
  //       workingPersonImg2x: images.ronakpic2x,
  //       workingPersonAlt: 'Ronak Kotecha',
  //     },
  //     {
  //       workingPersonClass: 'light-peach-six',
  //       workingPersonName: 'Chintan Prajapati',
  //       workingPersonDesignation: 'QA Engineer',
  //       workingPersonPara:
  //         'An analyst with experience in QA for products with more than 80 million daily active users.',
  //       workingPersonTag: ['Automation Testing', 'QA'],
  //       workingPersonImg: images.chintanpic,
  //       workingPersonImg2x: images.chintanpic2x,
  //       workingPersonAlt: 'Chintan Prajapati',
  //     },

  //     {
  //       workingPersonClass: 'light-grey-blue-two',
  //       workingPersonName: 'Juan Elfers',
  //       workingPersonDesignation: 'Sr. Front-End Developer ',
  //       workingPersonPara:
  //         'Fanatic about building websites using MEAN & MERN stack. Also, obsessed with clean code. ',
  //       workingPersonTag: ['NodeJS', 'ReactJS'],
  //       workingPersonImg: images.juanpic,
  //       workingPersonImg2x: images.juanpic2x,
  //       workingPersonAlt: 'Juan Elfers',
  //     },
  //     {
  //       workingPersonClass: 'light-blue-grey-three',
  //       workingPersonName: 'Steve Belovarich',
  //       workingPersonDesignation: 'Full Stack JS Engineer',
  //       workingPersonPara:
  //         'UI components developer for enterprise web apps with skills in Node Express & CSS architectures.',
  //       workingPersonTag: ['AngularJS', 'HTML/CSS'],
  //       workingPersonImg: images.stevepic,
  //       workingPersonImg2x: images.stevepic2x,
  //       workingPersonAlt: 'Steve Belovarich',
  //     },
  //     {
  //       workingPersonClass: 'light-grey-blue-four',
  //       workingPersonName: 'Christopher Northfield',
  //       workingPersonDesignation: 'Web Developer',
  //       workingPersonPara:
  //         'A web app developer who believes in writing semantic code and always sticking to KISS principle. ',
  //       workingPersonTag: ['AngularJS', 'C#'],
  //       workingPersonImg: images.christopherpic,
  //       workingPersonImg2x: images.christopherpic2x,
  //       workingPersonAlt: 'Christopher Northfield',
  //     },
  //     {
  //       workingPersonClass: 'cloudy-blue-two',
  //       workingPersonName: 'Martin K.',
  //       workingPersonDesignation: 'Enterprise PHP Developer',
  //       workingPersonPara:
  //         'Skilled RESTful API developer and a huge proponent of coding standard (PSR FIG) & CI/CD.  ',
  //       workingPersonTag: ['PHP', 'Web App'],
  //       workingPersonImg: images.martinpic,
  //       workingPersonImg2x: images.martinpic2x,
  //       workingPersonAlt: 'Martin K.',
  //     },
  //     {
  //       workingPersonClass: 'cloudy-blue-three',
  //       workingPersonName: 'Zach Layng',
  //       workingPersonDesignation: 'Full Stack Software Developer',
  //       workingPersonPara:
  //         'Uses Python as a swiss knife & Django as a go-to framework for building MVP. Also, a cool mentor.',
  //       workingPersonTag: ['Django', 'Python'],
  //       workingPersonImg: images.zachpic,
  //       workingPersonImg2x: images.zachpic2x,
  //       workingPersonAlt: 'Zach Layng',
  //     },
  //     {
  //       workingPersonClass: 'pale-salmon',
  //       workingPersonName: 'Tom Carrick',
  //       workingPersonDesignation: 'Software Engineer',
  //       workingPersonPara:
  //         'Not old, but experienced using Python for integrating APIs and natural language processing. ',
  //       workingPersonTag: ['Django', 'Python'],
  //       workingPersonImg: images.tompic,
  //       workingPersonImg2x: images.tompic2x,
  //       workingPersonAlt: 'Tom Carrick',
  //     },
  //     {
  //       workingPersonClass: 'cloudy-blue-four',
  //       workingPersonName: 'Abdulmalik Yusuf',
  //       workingPersonDesignation: 'DevOps Engineer',
  //       workingPersonPara:
  //         'Someone who possesses awesome skills releasing/managing deployments, autoscaling servers & CD/CI.',
  //       workingPersonTag: ['AWS/Docker', 'CI/CD'],
  //       workingPersonImg: images.abdulmalikpic,
  //       workingPersonImg2x: images.abdulmalikpic2x,
  //       workingPersonAlt: 'Abdulmalik Yusuf',
  //     },
  //     {
  //       workingPersonClass: 'light-grey-blue',
  //       workingPersonName: 'Zeke Nierenberg',
  //       workingPersonDesignation: 'JS Developer',
  //       workingPersonPara:
  //         'Ex-professor, lead instructor at Full Stack Academy of Code and Godfather to our developers.',
  //       workingPersonTag: ['Express', 'React'],
  //       workingPersonImg: images.zekepic,
  //       workingPersonImg2x: images.zekepic2x,
  //       workingPersonAlt: 'Zeke Nierenberg',
  //     },
  //     {
  //       workingPersonClass: 'light-blue-grey-three',
  //       workingPersonName: 'Rukayat Odukoya',
  //       workingPersonDesignation: 'Software Engineer',
  //       workingPersonPara:
  //         'Experienced developer & creative designer with a knack for software designing and code refactoring. ',
  //       workingPersonTag: ['NodeJS', 'ExpressJS'],
  //       workingPersonImg: images.rukayatpic,
  //       workingPersonImg2x: images.rukayatpic2x,
  //       workingPersonAlt: 'Rukayat Odukoya',
  //     },
  //     {
  //       workingPersonClass: 'light-peach-seven',
  //       workingPersonName: 'Nathan Gopen',
  //       workingPersonDesignation: 'Mobile App Developer',
  //       workingPersonPara:
  //         'MIT graduate helping businesses in building mobile apps, be it native, hybrid or cross-platform. ',
  //       workingPersonTag: ['iOS', 'Android'],
  //       workingPersonImg: images.nathanpic,
  //       workingPersonImg2x: images.nathanpic2x,
  //       workingPersonAlt: 'Nathan Gopen',
  //     },
  //     {
  //       workingPersonClass: 'light-blue-grey-four',
  //       workingPersonName: 'Ekim Unal',
  //       workingPersonDesignation: 'Shopify Specialist',
  //       workingPersonPara:
  //         'An e-commerce specialist with an inclination towards Shopify, xamarin and smart contracts.',
  //       workingPersonTag: ['Xamarin', 'Shopify'],
  //       workingPersonImg: images.ekimpic,
  //       workingPersonImg2x: images.ekimpic2x,
  //       workingPersonAlt: 'Ekim Unal',
  //     },
  //     {
  //       workingPersonClass: 'cloudy-blue-five',
  //       workingPersonName: 'Ervis Trupja',
  //       workingPersonDesignation: 'Full Stack Developer',
  //       workingPersonPara:
  //         'High affinity for Microsoft platforms and .NET. 2 MCSD certificate holder and trainer.',
  //       workingPersonTag: ['Asp .Net', 'C#'],
  //       workingPersonImg: images.ervispic,
  //       workingPersonImg2x: images.ervispic,
  //       workingPersonAlt: 'Ervis Trupja',
  //     },
  //     {
  //       workingPersonClass: 'cloudy-blue-six',
  //       workingPersonName: 'Zoltan Varadi',
  //       workingPersonDesignation: 'Software Developer',
  //       workingPersonPara:
  //         'People & project manager with a keen eye on implementing pixel perfect design & UI/UX. ',
  //       workingPersonTag: ['UI/UX', 'Swift'],
  //       workingPersonImg: images.zoltanpic,
  //       workingPersonImg2x: images.zoltanpic2x,
  //       workingPersonAlt: 'Zoltan Varadi',
  //     },
  //     {
  //       workingPersonClass: 'pinkish-grey',
  //       workingPersonName: 'Andy Jacobs',
  //       workingPersonDesignation: 'Software Developer ',
  //       workingPersonPara:
  //         'Technical consultant for B2C & B2B applications who can make high-end production a reality.',
  //       workingPersonTag: ['Objective C', 'Xcode'],
  //       workingPersonImg: images.andypic,
  //       workingPersonImg2x: images.andypic2x,
  //       workingPersonAlt: 'Andy Jacobs',
  //     },
  //     {
  //       workingPersonClass: 'cloudy-blue-seven',
  //       workingPersonName: 'Rinat Sharipov',
  //       workingPersonDesignation: 'Senior iOS Engineer',
  //       workingPersonPara:
  //         'Experienced engineer in developing REST APIs, core animation and full control customizations.',
  //       workingPersonTag: ['Swift4', 'Objective C'],
  //       workingPersonImg: images.rinatpic,
  //       workingPersonImg2x: images.rinatpic2x,
  //       workingPersonAlt: 'Rinat Sharipov',
  //     },
  //     {
  //       workingPersonClass: 'light-blue-grey',
  //       workingPersonName: 'Adel Tahir',
  //       workingPersonDesignation: 'Full Stack Developer',
  //       workingPersonPara:
  //         'iOS & Android app developer with solid experience in React/Redux, Node/Express and PHP/Laravel.',
  //       workingPersonTag: ['ReactJS', 'AngularJS'],
  //       workingPersonImg: images.adelpic,
  //       workingPersonImg2x: images.adelpic2x,
  //       workingPersonAlt: 'Adel Tahir',
  //     },
  //     {
  //       workingPersonClass: 'light-grey-blue-three',
  //       workingPersonName: 'Christopher Esplin',
  //       workingPersonDesignation: 'Serverless Developer',
  //       workingPersonPara:
  //         'A cool dev currently focusing on developing real-time, single page apps utilizing material design.',
  //       workingPersonTag: ['Serverless', 'Firebase'],
  //       workingPersonImg: images.christopheresplinpic,
  //       workingPersonImg2x: images.christopheresplinpic2x,
  //       workingPersonAlt: 'Christopher Esplin',
  //     },
  //     {
  //       workingPersonClass: 'pinkish-grey-two',
  //       workingPersonName: 'David Baumgold',
  //       workingPersonDesignation: 'Web App Developer',
  //       workingPersonPara:
  //         'Full stack wiz who uses Redux & GraphQL for building complex & powerful browser-based web apps. ',
  //       workingPersonTag: ['jQuery', 'Python'],
  //       workingPersonImg: images.davidpic,
  //       workingPersonImg2x: images.davidpic2x,
  //       workingPersonAlt: 'David Baumgold',
  //     },
  //     {
  //       workingPersonClass: 'light-blue-grey-three',
  //       workingPersonName: 'Anna Berman',
  //       workingPersonDesignation: 'Software Developer',
  //       workingPersonPara:
  //         'Expert architecture of distributed, real-time and full-stack solutions for web & iPhone',
  //       workingPersonTag: ['NodeJS', 'Firebase'],
  //       workingPersonImg: images.annapic,
  //       workingPersonImg2x: images.annapic2x,
  //       workingPersonAlt: 'Anna Berman',
  //     },
  //   ],
  // },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/cloud-cost-optimization-strategies-compute-storage/ ',
        featurecardTitle:
          'Cloud Cost Optimization Strategies (Even AWS won’t Tell you!)',
      },
      {
        blogLink: 'https://www.simform.com/serverless-architecture-guide/',
        featurecardTitle: 'Serverless Architecture: A Comprehensive Guide',
      },
      {
        blogLink: 'https://www.simform.com/mongodb-vs-mysql-databases/',
        featurecardTitle: 'MongoDB vs MySQL: A Comparative Study on Databases',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactHs,
    personImg2x: images.contactHs2x,
    personName: 'Hardik Shah',
    personDesignation:
      'Creating a tech product roadmap and building scalable apps for your organization.',
    personEmail: 'hardik@simform.com',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
